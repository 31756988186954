<template>
  <div>
    <a-card style="height: calc(50vh - 40px);">
      <div slot="title">
        <span>库存Top10产品</span>
        <div id="InventoryTopProduct" style="margin-top: 36px;"></div>
      </div>
    </a-card>
  </div>
</template>

<script>
  import { inventoryTopProduct } from '@/api/statistic'

  export default {
    data() {
      return {
        chart: null,
        loading: false,
      };
    },
    methods: {
      initialize() {
        let height = window.innerHeight / 2 - 141;
        this.chart = new window.G2.Chart({ container: 'InventoryTopProduct', autoFit: true, height });
        this.chart.scale('inventory_quantity', { alias: '库存数量' });
        this.chart.interval().position('goods_name*inventory_quantity');
        this.list();
      },
      list() {
        this.loading = true;
        inventoryTopProduct().then(resp => {
          this.chart.changeData(resp);
          this.chart.forceFit();
        }).finally(() => {
          this.loading = false;
        });
      },
      changeDateRange(dateRange) {
        this.dateRange = dateRange;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
</style>